import http from "@/utils/request";
// 列表查询
export function getreportList(data) {
  return http({
    method: "post",
    url: "/fast/orderServiceFee/orderServiceFee/page.nd",
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data
  });
}

export function getExport(data) {
  return http({
    url: '/fast/potential/shopPotentialShortMessage/export.nd',
    method: 'post',
    // contentType: "application/json;charset=UTF-8",
    // type: 'stringfy',
    data:data,
    responseType: 'blob'
  })
}