import {getreportList ,getExport } from "./api";
import Util from "@/utils/utils";
export default {
  components: {
  },
  data() {
    return {
      nolistImg: require("@/assets/IntentionList/none.png"),
      pageLoadFlag: false,
      isLoading:false,
      arg:{},
      tableList:[
        {

        }
      ],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },

        {
          path: "/serviceFeeModule/serviceFreeList",
          name: "",
          title: "服务费单据"
        }
      ],
      totalPages:0,
      totalRows:0,
      filterFrom: {
        pageNo: 1,
        pageSize: 9,
      },
      isFixed: false,
      allChecked:false,
      checkCodeList:[]
    };
  },
  mounted() {
    window.addEventListener("scroll", this.throttle(this.handleScroll, 100), true);

    this.getList()
  },
  methods: {
    // 滚动
    handleScroll() {
      if (this.$refs.footerRef) {
        this.isFixed = !this.isElementInViewport(this.$refs.footerRef);
      }
    },
    // 滚动页面
    throttle(func, delay) {
      var prev = Date.now();
      return function() {
        var context = this;
        var args = arguments;
        var now = Date.now();
        if (now - prev >= delay) {
          func.apply(context, args);
          prev = Date.now();
        }
      };
    },
    isElementInViewport(el) {
      //获取元素是否在可视区域
      const rect = el.getBoundingClientRect();
      return (
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    // 导出
    exportClick() {
      let data = {
        ...this.arg
      }
      this.isLoading = true;
      getExport(data)
        .then(res => {
          this.isLoading = false;
          Util.blobToJson(res.data)
            .then(content => {
              if (content && content.msg == "success") {
                this.$message.success("下载成功，请到下载中心查看!");
              }
            })
            .catch(err => {
              Util.downloadFile(res.data, "触达记录.xls");
            })
            .finally(() => {
              this.expLoading = false;
            });
        })
        .finally(res => {
          this.isLoading = false;
        });
    },
    searchFun(arg) {
      this.arg = arg[0];
      this.filterFrom.pageNo = 1
      this.getList();
    },
    loadMore() {
      this.filterFrom.pageNo =  this.filterFrom.pageNo + 1,
      this.getList()
    },
    changeCheckItem(item,e) {
      item.check = e.target.checked
      this.checkCodeList =  this.tableList.filter(item=>item.check)
      this.$forceUpdate()
    },
    allCheckClick(e){
      this.allChecked = e.target.checked
      this.$forceUpdate()
      if (e.target.checked &&  this.tableList.length>0) {
        //只有状态为待支付状态才会把复选框赋值为勾选状态
        this.tableList.forEach(item=>{
         if( item.status == 1){
           item.check = true
           this.$forceUpdate()
         }
        })
      } else {
        //只有状态为待支付状态才会把复选框赋值为勾选状态
        this.tableList.forEach(item=>{
          if( item.status == 1){
            item.check = false
            this.$forceUpdate()
          }
        })
      }
      this.checkCodeList =  this.tableList.filter(item=>item.check)

    },
    // 批量支付
    allPay(){
      let checkCode = []
      if(this.tableList.length>0){
        this.tableList.forEach(item=>{
          if( item.check ){
            checkCode.push(item.feeCode)
          }
        })
      }
      if (checkCode.length == 0) {
        this.$message.warning('请选择单据进行支付')
        return
      }
      this.$router.push({
        path: "/serviceFeeModule/pay",
        query: {
          id:checkCode.join(','),
          batch:1,
          type:'list'
        }
      });


    },
    // 查看详情
    gotoDetail(item){
      // let routeUrl = this.$router.resolve({
      //   path: '/serviceFeeModule/serviceDetail',
      //   query: {
      //     id:item.feeCode
      //   }
      // });
      // window.open(routeUrl.href, "_blank");
      this.$router.push({
        path: "/serviceFeeModule/serviceDetail",
        query: {
          id:item.feeCode
        }
      });
    },
    goPay(item){
      // let routeUrl = this.$router.resolve({
      //   path: '/serviceFeeModule/pay',
      //   query: {
      //     id:item.feeCode,
      //     batch:0,
      //   }
      // });
      // window.open(routeUrl.href, "_blank");
      this.$router.push({
        path: "/serviceFeeModule/payDetail",
        query: {
          id:item.feeCode,
          batch:0,
          type:'list'
        }
      });
      // this.$router.push({
      //   path: "/serviceFeeModule/pay",
      //   query: {
      //     id:item.feeCode,
      //     batch:0,
      //   }
      // });
    },
    getList(){
      this.pageLoadFlag=true
      let data ={
        pageSize: this.filterFrom.pageSize,
        pageNo:  this.filterFrom.pageNo,
        ...this.arg
      }
      getreportList(data).then(res=>{
        if(res.data.code == 0) {
          this.totalRows = res.data.totalRows
          this.totalPages = res.data.totalPages
          this.currentPage =  res.data.currentPage
          if( this.filterFrom.pageNo > 1 && res.data.list.length>0) {
            this.tableList = this.tableList.concat(res.data.list);
          } else {
            this.tableList = res.data.list
          }
          if(res.data.totalPages == 0) {
            this.tableList = []
          }
          if( this.tableList.length>0){
            this.tableList.forEach(item=>{
              item.check = false
            })
          }
         this.tableList.forEach(el => {
            el.leftTime = 0
            if(el.status == 1) {
              let  date = new Date();
              let  now = date.getTime();
              //设置截止时间
              // var endDate = new Date("2020-1-22 23:23:23");
              var end = el.createdDateEnd;
              //时间差
              el.leftTime = end - now;
              //定义变量 d,h,m,s保存倒计时的时间
              if (el.leftTime >= 0) {
                el.d = Math.floor(el.leftTime / 1000 / 60 / 60 / 24);
                el.h = Math.floor(el.leftTime / 1000 / 60 / 60 % 24);
                el.m = Math.floor(el.leftTime / 1000 / 60 % 60) < 10?'0'+ Math.floor(el.leftTime / 1000 / 60 % 60):Math.floor(el.leftTime / 1000 / 60 % 60);
                el.s = Math.floor(el.leftTime / 1000 % 60)< 10?'0'+ Math.floor(el.leftTime / 1000 % 60):Math.floor(el.leftTime / 1000 % 60);
                el.sum_h = this.d * 24 + this.h
              }
            }
          })
        } else {
          this.tableList = []
          this.totalPages = 0
          this.$message.warning('查询失败'+res.data.msg)
        }
        this.pageLoadFlag=false
      }).catch(error=>{
        this.pageLoadFlag=true
      })
    }
  },
// 倒计时
  countTime: function () {
    // 获取当前时间
    let  date = new Date();
    let  now = date.getTime();
    //设置截止时间
    // var endDate = new Date("2020-1-22 23:23:23");
    var end = this.expireDate;
    //时间差
    var leftTime = end - now;
    //定义变量 d,h,m,s保存倒计时的时间
    if (leftTime >= 0) {
      this.d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      this.h = Math.floor(leftTime / 1000 / 60 / 60 % 24);
      this.m = Math.floor(leftTime / 1000 / 60 % 60) < 10?'0'+ Math.floor(leftTime / 1000 / 60 % 60):Math.floor(leftTime / 1000 / 60 % 60);
      this.s = Math.floor(leftTime / 1000 % 60)< 10?'0'+ Math.floor(leftTime / 1000 % 60):Math.floor(leftTime / 1000 % 60);
      this.sum_h = this.d * 24 + this.h
    }
    //递归每秒调用countTime方法，显示动态时间效果
    // setTimeout(this.countTime, 1000);
  },

};
